<!--suppress HtmlUnknownAttribute, RedundantIfStatementJS, JSUnresolvedVariable -->
<template>
  <div class="about">
    <v-alert
        color="yellow"
        outlined
        text
        type="error"
        class="plugin-alert"
    >
      <p>These plugin lists are dynamically generated and not vetted in the least. They may contain malware or jeopardize your account. Proceed with caution and understand what you are installing.</p>
      <p>If you are installing any third-party Dalamud plugins - DO NOT ask for help on the XIVLauncher discord. Instead, please review the plugin's README on GitHub. This will usually contain support instructions or a link to their discord server.</p>
    </v-alert>
    <h3>Change Log</h3>
    <ul>
      <li class="list-item">2023-01-28: Updated the about page.</li>
      <li class="list-item">2022-10-23: Improved plugin master last updated / committed logic.</li>
      <li class="list-item">2022-09-24: Show last updated date for closed source plugin masters.</li>
      <li class="list-item">2022-09-08: Added mega repo icon and squashed a few smaller bugs.</li>
      <li class="list-item">2022-09-03: Fixed bug that was hiding certain non-github plugins on site.</li>
      <li class="list-item">2022-07-26: Updated styling and tried to make mobile less bad.</li>
      <li class="list-item">2022-07-25: Re-released neat-plugins-plus as XIV Plugins.</li>
    </ul>
    <h3>About</h3>
    <div class="about"><v-icon>mdi-information-outline</v-icon>All the ACT and Dalamud Plugins that can be found on github and a few more.</div>
    <div class="about"><v-icon>mdi-github</v-icon>Report bugs or feature requests for this site on <a :href="`https://github.com/kalilistic/xivplugins/issues`">github</a>.</div>
    <div class="about"><v-icon>mdi-database</v-icon>Learn how to use the site's APIs on <a :href="`https://app.swaggerhub.com/apis/kalilistic/xiv-plugins-api`">swagger</a>.</div>
    <div class="about"><v-icon>mdi-patreon</v-icon>Support me on <a :href="`https://www.patreon.com/kalilistic`">patreon</a>.</div>
    <div style="margin-top: 2em">XIVPlugins is in no way affiliated with SQUARE ENIX CO., LTD. or FINAL FANTASY XIV.</div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss">
.about {
  margin-bottom: 1em;
}
.plugin-alert {
  margin-top: 1.5em;
  max-width: 60em;
}
h3 {
  margin-bottom: 1em;
  margin-top: 1.5em;
}
.about {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  .v-icon {
    margin-right: 0.2em;
  }
}
</style>
