<!--suppress HtmlUnknownAttribute, RedundantIfStatementJS, JSUnresolvedVariable -->
<template>
  <v-card class="container">
    <v-card-title color="basil" class="text-center">
      <h1 class="font-weight-bold text-h3 basil--text" @click="$router.push('/').catch(err => {})">XIV Plugins</h1>
    </v-card-title>
    <v-tabs
        background-color="transparent"
        color="basil"
        grow
        show-arrows
    >
      <v-tab to="/">About</v-tab>
      <v-tab to="/act-plugins">ACT Plugins</v-tab>
      <v-tab to="/act-overlays">ACT Overlays</v-tab>
      <v-tab to="/dalamud-plugins">Dalamud Plugins</v-tab>
      <v-tab to="/dalamud-repos">Dalamud Repos</v-tab>
    </v-tabs>
    <router-view/>
  </v-card>
</template>

<style lang="scss">
.container {
  margin-bottom: 1.2em;
}
.v-data-table td {
  border: none !important;
  white-space: nowrap !important;
}
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
.search-container {
  padding-left: 1.5em;
  max-width: 30em;
  margin-top: 0.5em !important;
}
.forks {
  padding-left: 1.5em;
}
.include-forks {
  margin-left: 1.5em;
  margin-top: -.8em !important;
}
.include-mega-repos {
  margin-left: 1.5em;
  margin-top: -.8em !important;
}
</style>

<script>
export default {
  name: "Home",
};
</script>
