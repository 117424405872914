import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/components/Home";
import DalamudPlugins from "@/components/DalamudPlugins";
import DalamudRepos from "@/components/DalamudRepos";
import About from "@/components/About";
import ACTPlugins from "@/components/ACTPlugins";
import ACTOverlays from "@/components/ACTOverlays";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        component: About,
      },
      {
        path: '/act-plugins',
        component: ACTPlugins
      },
      {
        path: '/act-overlays',
        component: ACTOverlays
      },
      {
        path: '/dalamud-plugins',
        component: DalamudPlugins
      },
      {
        path: '/dalamud-repos',
        component: DalamudRepos
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
