<!--suppress HtmlUnknownAttribute, RedundantIfStatementJS, JSUnresolvedVariable -->
<template>
  <div>
    <v-row class="search-container">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          class="search"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="includeBranches"
        :label="`Include Branches`"
        class="include-forks"
      ></v-checkbox>
      <v-checkbox
        v-model="includeOutdatedAPILevels"
        :label="`Include Outdated API Levels`"
        class="include-forks"
      ></v-checkbox>
<!--      <v-checkbox-->
<!--          v-model="includeMegaRepos"-->
<!--          :label="`Include Mega Repos`"-->
<!--          class="include-mega-repos"-->
<!--      ></v-checkbox>-->
    </v-row>
    <v-data-table
      :headers="headers"
      :items="repos"
      item-key="id"
      sort-by="updatedAt"
      :sortDesc=true
      class="elevation-1"
      :search="search"
      :custom-filter="filterRows"
      dense
      :items-per-page=-1
      :loading="isLoading"
      loading-text="Loading... please wait a moment."
      show-expand
      mobile-breakpoint="0"
    >
      <template #item.name="{ item }">
        <a :href="item.gitRepoUrl" class="url">
          {{ item.name }}
        </a>
        <v-tooltip bottom v-if="item.isMegaRepo" color="black" content-class='custom-tooltip'>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
                class="ma-2"
                color="warning"
                small
                outlined
                pill
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon small left>
                mdi-alert
              </v-icon>
              Mega Repo
            </v-chip>
          </template>
          <div class="orange--text font-weight-bold">Please ensure you understand the higher security risk before using this repository.</div>
          <div>This appears to be a repository that aggregates plugins from multiple developers.</div>
          <div>By installing this repository, you potentially give access to all developers on the repository.</div>
          <div>This risk exists even without installing a plugin from the developer.</div>
        </v-tooltip>
      </template>
      <template #item.owner="{ item }">
        <a :href="item.ownerHtmlUrl" class="url">{{item.owner}}</a>
      </template>
      <template #item.pluginMasterUrl="{ item }">
        <v-btn icon v-clipboard:copy="item.pluginMasterUrl">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon :href="item.pluginMasterUrl" target="_blank">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template #item.isDefaultBranch="{ item }">
        <v-icon v-if="item.isDefaultBranch === true">mdi-check</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="pluginHeaders.length">
          <v-data-table
            :headers="pluginHeaders"
            :items="item.plugins"
            class="elevation-1"
            item-key="id"
            sort-by="name"
            :sortDesc=false
            dense
            :items-per-page=-1
            hide-default-footer
          ></v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DalamudRepos",
  data() {
    return {
      includeBranches: false,
      includeOutdatedAPILevels: false,
      includeMegaRepos: false,
      search: '',
      repos: [],
      isLoading: true,
    };
  },
  methods: {
    filterRows(value, search, item) {
      if (!this.includeBranches && !item.isDefaultBranch) return false;
      if (!this.includeOutdatedAPILevels && item.dalamudApiLevel !== 10) return false;
      if (!this.includeMegaRepos && item.isMegaRepo) return false;
      if (search == null) return true;
      let adjSearch = search.replace(/\s/g, '').toLowerCase();
      if (item.name.toLowerCase().includes(adjSearch) || item.owner.toLowerCase().includes(adjSearch)) return true;
      for (let plugin of item.plugins) {
        if (plugin.name.replace(/\s/g, '').toLowerCase().includes(adjSearch)) return true;
      }
      return false;
    },
  },
  computed: {
    pluginHeaders () {
      return [
        { text: "Name", align: "start", value: "name", groupable: false, },
        { text: "Version", align: "start", value: "assemblyVersion", groupable: false, },
        { text: "Description", align: "start", value: "desc", groupable: false, },
      ]
    },
    headers () {
      return [
        { text: "Name", align: "start", value: "name", groupable: false, },
        { text: "Developer", align: "start", value: "owner", groupable: true, },
        { text: "Last Updated", align: "start", value: "updatedAt", groupable: false, },
        { text: "Last Committed", align: "start", value: "committedAt", groupable: false, },
        { text: "API Level", align: "start", value: "dalamudApiLevel", groupable: false,
          filter: value => {
            if (this.search) return true;
            if (this.includeOutdatedAPILevels) return true;
            return value === 10;
          },
        },
        {
          text: "Is Default Branch", align: "start", value: "isDefaultBranch", groupable: false,
          filter: value => {
            if (this.search) return true;
            if (this.includeBranches) return true;
            return value === true;
          },
        },
        // {
        //   text: "Is Mega Repo", align: "start", value: "isMegaRepo", groupable: false,
        //   filter: value => {
        //     if (this.search) return true;
        //     if (this.includeMegaRepos) return true;
        //     return value === false;
        //   },
        // },
        { text: "Plugin Count", align: "start", value: "pluginsCount", groupable: false, },
        { text: "Stargazers", align: "start", value: "stargazersCount", groupable: false, },
        { text: "Plugin Master", align: "d-none", value: "pluginMasterUrl", groupable: false, }
      ]
    },
  },
  mounted() {
    axios.get("https://api.xivplugins.com/v1/dalamud/repos").then((response) => {
      const repos = [];
      for (let repo of response.data) {
        try {
          repo.name = repo.fullName;
          if (!Object.prototype.hasOwnProperty.call(repo, 'isDefaultBranch')) {
            repo.isDefaultBranch = true;
          }
          // if (!Object.prototype.hasOwnProperty.call(repo, 'isMegaRepo')) {
          //   repo.isMegaRepo = false;
          // }
          if (!repo.isDefaultBranch && repo.branchName) {
            repo.name = repo.name + " (" + repo.branchName + ")";
          }
          repo.pluginsCount = repo.plugins.length;
          if (repo.updatedAt) {
            repo.updatedAt = new Date(+repo.updatedAt).toISOString().split('T')[0];
          }
          if (repo.committedAt) {
            repo.committedAt = new Date(+repo.committedAt).toISOString().split('T')[0];
            if (repo.updatedAt > repo.committedAt) {
              repo.updatedAt = repo.committedAt;
            }
          }
          if (!repo.gitRepoUrl) {
            repo.gitRepoUrl = repo.pluginMasterUrl;
          }
          if (!repo.ownerHtmlUrl) {
            repo.ownerHtmlUrl = repo.pluginMasterUrl;
          }
          for (let plugin of repo.plugins) {

            if (plugin.punchline) {
              plugin.desc = plugin.punchline;
            }
            else if (plugin.description) {
              plugin.desc = plugin.description;
            }
          }
        }
        catch (err) {
          console.log("skipping " + repo?.fullName)
        }

        repos.push(repo);
      }

      this.repos = repos;
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss">
.copy {
  margin-right: 0.25em;
}
.expand-all {
  margin-left: 1.5em;
  margin-top: 1.2em;
}
.spacer {
  margin-right: 0.25em;
}
.custom-tooltip {
  opacity: var(--v-tooltip-opacity, 1) !important;
  background: var(--v-tooltip-bg, rgba(97, 97, 97, 1)) !important;
}
</style>
