<!--suppress HtmlUnknownAttribute, RedundantIfStatementJS, JSUnresolvedVariable -->
<template>
  <div>
    <v-row>
      <v-col cols="8">
        <v-row class="search-container">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            class="search"
          ></v-text-field>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="overlays"
      item-key="id"
      sort-by="lastUpdated"
      :sortDesc=true
      class="elevation-1"
      :search="search"
      :custom-filter="filterRows"
      dense
      :items-per-page=-1
      :loading="isLoading"
      loading-text="Loading... please wait a moment."
      mobile-breakpoint="0"
    >
      <template #item.name="{ item }">
        <a :href="item.gitRepoUrl" class="url">
          {{ item.name }}
        </a>
      </template>
      <template #item.owner="{ item }">
        <a :href="item.ownerHtmlUrl" class="url">
          {{ item.owner }}
        </a>
      </template>
      <template #item.overlayUrl="{ item }">
        <v-btn icon v-clipboard:copy="item.overlayUrl">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon :href="item.overlayUrl" target="_blank">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ACTOverlays",
  data() {
    return {
      includeForks: true,
      search: '',
      overlays: [],
      isLoading: true,
    };
  },
  methods: {
    filterRows(value, search, item) {
      if (!this.includeForks && item.isFork) return false;
      if (search == null) return true;
      if (item.name.toLowerCase().includes(search.toLowerCase()) || item.owner.toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    },
  },
  computed: {
    headers () {
      return [
        { text: "Name", align: "start", value: "name", groupable: false, },
        { text: "Developer", align: "start", value: "owner", groupable: true, },
        { text: "Last Updated", align: "start", value: "lastUpdated", groupable: false, },
        { text: "Stargazers", align: "start", value: "stargazersCount", groupable: false, },
        { text: "Overlay URL", align: "start", value: "overlayUrl", groupable: false, }
      ]
    },
  },
  mounted() {
    axios.get("https://api.xivplugins.com/v1/act/overlays").then((response) => {
      const overlays = [];
      for (let plugin of response.data) {
        if (plugin.lastUpdated) {
          plugin.lastUpdated = new Date(+plugin.lastUpdated).toISOString().split('T')[0];
        }
        if (plugin.created) {
          plugin.created = new Date(+plugin.created).toISOString().split('T')[0];
        }
        plugin.source = plugin.sourceName;
        if (plugin.isFork) {
          plugin.name = `${plugin.name} (fork)`
        }
        plugin.isFork = !!plugin.isFork;
        plugin.forksCount = plugin.forksCount ?? 0;
        overlays.push(plugin);
      }
      this.overlays = overlays;
      this.isLoading = false;
    });
  },
};
</script>
