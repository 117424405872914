var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"search-container"},[_c('v-text-field',{staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-checkbox',{staticClass:"include-forks",attrs:{"label":"Include Branches"},model:{value:(_vm.includeBranches),callback:function ($$v) {_vm.includeBranches=$$v},expression:"includeBranches"}}),_c('v-checkbox',{staticClass:"include-forks",attrs:{"label":"Include Outdated API Levels"},model:{value:(_vm.includeOutdatedAPILevels),callback:function ($$v) {_vm.includeOutdatedAPILevels=$$v},expression:"includeOutdatedAPILevels"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.repos,"item-key":"id","sort-by":"updatedAt","sortDesc":true,"search":_vm.search,"custom-filter":_vm.filterRows,"dense":"","items-per-page":-1,"loading":_vm.isLoading,"loading-text":"Loading... please wait a moment.","show-expand":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"url",attrs:{"href":item.gitRepoUrl}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.isMegaRepo)?_c('v-tooltip',{attrs:{"bottom":"","color":"black","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"warning","small":"","outlined":"","pill":"","dark":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-alert ")]),_vm._v(" Mega Repo ")],1)]}}],null,true)},[_c('div',{staticClass:"orange--text font-weight-bold"},[_vm._v("Please ensure you understand the higher security risk before using this repository.")]),_c('div',[_vm._v("This appears to be a repository that aggregates plugins from multiple developers.")]),_c('div',[_vm._v("By installing this repository, you potentially give access to all developers on the repository.")]),_c('div',[_vm._v("This risk exists even without installing a plugin from the developer.")])]):_vm._e()]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"url",attrs:{"href":item.ownerHtmlUrl}},[_vm._v(_vm._s(item.owner))])]}},{key:"item.pluginMasterUrl",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.pluginMasterUrl),expression:"item.pluginMasterUrl",arg:"copy"}],attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":"","href":item.pluginMasterUrl,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.isDefaultBranch",fn:function(ref){
var item = ref.item;
return [(item.isDefaultBranch === true)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.pluginHeaders.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.pluginHeaders,"items":item.plugins,"item-key":"id","sort-by":"name","sortDesc":false,"dense":"","items-per-page":-1,"hide-default-footer":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }