<!--suppress HtmlUnknownAttribute, RedundantIfStatementJS, JSUnresolvedVariable -->
<template>
  <div>
    <v-row class="search-container">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          class="search"
      ></v-text-field>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="plugins"
      item-key="id"
      sort-by="stargazersCount"
      :sortDesc=true
      class="elevation-1"
      :search="search"
      :custom-filter="filterRows"
      dense
      :items-per-page=-1
      :loading="isLoading"
      loading-text="Loading... please wait a moment."
      mobile-breakpoint="0"
    >
      <template #item.name="{ item }">
        <a :href="item.gitRepoUrl" class="url">
          {{ item.name }}
        </a>
      </template>
      <template #item.owner="{ item }">
        <a :href="item.ownerHtmlUrl" class="url">
          {{ item.owner }}
        </a>
      </template>
      <template #item.inACTInstaller="{ item }">
        <v-icon v-if="item.inACTInstaller === true">mdi-check</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ACTPlugins",
  data() {
    return {
      includeForks: true,
      search: '',
      plugins: [],
      isLoading: true,
    };
  },
  methods: {
    filterRows(value, search, item) {
      if (!this.includeForks && item.isFork) return false;
      if (search == null) return true;
      if (item.name.toLowerCase().includes(search.toLowerCase()) || item.owner.toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    },
  },
  computed: {
    headers () {
      return [
        { text: "Name", align: "start", value: "name", groupable: false, },
        { text: "Developer", align: "start", value: "owner", groupable: true, },
        { text: "Last Updated", align: "start", value: "lastUpdated", groupable: false, },
        { text: "Last Published", align: "start", value: "lastPublished", groupable: false, },
        { text: "In ACT Installer", align: "start", value: "inACTInstaller", groupable: false, },
        { text: "Stargazers", align: "start", value: "stargazersCount", groupable: false, },
      ]
    },
  },
  mounted() {
    axios.get("https://api.xivplugins.com/v1/act/plugins").then((response) => {
      const plugins = [];
      for (let plugin of response.data) {
        if (plugin.lastUpdated) {
          plugin.lastUpdated = new Date(+plugin.lastUpdated).toISOString().split('T')[0];
        }
        if (plugin.lastPublished) {
          plugin.lastPublished = new Date(+plugin.lastPublished).toISOString().split('T')[0];
          plugin.inACTInstaller = true;
        }
        plugins.push(plugin);
      }
      this.plugins = plugins;
      this.isLoading = false;
    });
  },
};
</script>
